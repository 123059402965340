import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import RestaurantDetail from './components/restaurants/RestaurantDetail';
import AdminLogin from './components/auth/AdminLogin';
import './index.css';

// Lazy-loaded components
const Home = lazy(() => import('./components/pages/Home'));
const Restaurants = lazy(() => import('./components/restaurants/Restaurants'));
const About = lazy(() => import('./components/pages/About'));
const Contact = lazy(() => import('./components/pages/Contact'));
const AdminDashboard = lazy(() => import('./components/auth/AdminDashboard'));

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/restaurants" element={<Restaurants />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/restaurants/:id" element={<RestaurantDetail />} />
              {/* Admin Login Route */}
              <Route path="/iamadmin-login" element={<AdminLogin />} />
              {/* Admin Dashboard Route */}
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              {/* Catch-all route for undefined paths */}
              <Route path="*" element={<div>404 Not Found</div>} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
