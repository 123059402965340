// File: /client/src/components/auth/AdminLogin.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/admin/iamadmin-login', formData, { withCredentials: true });
      navigate('/admin-dashboard');
    } catch (err) {
      setErrorMessage('Invalid credentials. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form className="bg-white p-8 rounded shadow-md w-96" onSubmit={onSubmit}>
        <h2 className="text-2xl font-bold text-center mb-4">Super Admin Login</h2>

        {errorMessage && (
          <div className="bg-red-200 text-red-700 p-2 mb-4 rounded">{errorMessage}</div>
        )}

        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-700 mb-2">
            Username
          </label>
          <input
            type="text"
            name="username"
            id="username"
            className="border border-gray-300 p-2 w-full rounded"
            value={formData.username}
            onChange={onChange}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 mb-2">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="border border-gray-300 p-2 w-full rounded"
            value={formData.password}
            onChange={onChange}
            required
          />
        </div>

        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded w-full">
          Log In
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
